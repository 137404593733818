import React from "react";
import useMatrix from "./useMatrix";

const MatrixCanvas: React.FC = () => {
  const canvasRef = useMatrix();

  return (
    <canvas
      ref={canvasRef}
      className="absolute top-0 left-0 w-full h-full bg-black/20"
    />
  );
};

export default MatrixCanvas;
