import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar/Navbar";
import MatrixCanvas from "../components/MatrixCanvas/MatrixCanvas";

const Home: React.FC = () => {
  const resources = {
    en: {
      "Home.tag": "AI-Powered Security",
      "Home.title1": "We're building ",
      "Home.title2": "frontier models",
      "Home.title3": " in ",
      "Home.title4": "offensive cyber",
      "Home.title5": "security",
      "Home.description":
        "We're pioneering next-generation AI models that revolutionize offensive security testing, enabling faster and more sophisticated vulnerability discovery than ever before.",
      "Home.contact": "Get a Demo",
      "Home.docs": "Strategic Memo",
    },
  };

  Object.keys(resources).forEach((lang) => {
    i18n.addResourceBundle(
      lang,
      "translation",
      resources[lang as keyof typeof resources],
      true,
      true,
    );
  });

  const { t } = useTranslation();

  return (
    <main className="min-h-screen bg-white text-black">
      <section className="relative min-h-screen flex flex-col">
        <div className="w-full max-w-[1280px] mx-auto px-8 mobile:px-6">
          <Navbar />

          <div className="flex-1 flex mobile:flex-col laptop:flex-row gap-24 laptop:gap-24 mobile:w-full tablet:w-full py-32 mobile:py-16 laptop:py-32">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex-1 flex flex-col justify-center mobile:items-center mobile:text-center mobile:w-full tablet:w-full"
            >
              <div className="inline-flex px-4 py-2 bg-black text-white rounded-full text-sm font-medium mb-8 mobile:self-center self-start">
                {t("Home.tag")}
              </div>

              <h1 className="text-[3.75rem] mobile:text-[2.5rem] font-semibold leading-[1.1] tracking-[-0.03em] mb-6">
                {t("Home.title1")}
                <motion.span
                  className="text-black/70 inline-block"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  {t("Home.title2")}
                </motion.span>
                {t("Home.title3")}
                <motion.span
                  className="text-black/70 inline-block"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  {t("Home.title4")}
                </motion.span>
                {t("Home.title5")}
              </h1>

              <p className="text-[1.25rem] leading-[1.6] text-black/70 mobile:text-base mb-12 max-w-[540px]">
                {t("Home.description")}
              </p>

              <div className="flex gap-6 laptop:gap-6 mobile:flex-col mobile:w-full">
                <motion.a
                  whileHover={{ y: -2 }}
                  href="mailto:contact@pyrrha.fr"
                  className="h-14 laptop:h-14 px-8 laptop:px-8 bg-black text-white rounded-lg font-semibold flex items-center justify-center hover:shadow-lg transition-shadow mobile:w-full"
                >
                  {t("Home.contact")}
                </motion.a>
                <a
                  href="https://drive.google.com/file/d/1wfyBYzfMuQqkdzZwZE2M1HLGQd6HnK14/view?usp=sharing"
                  className="h-14 laptop:h-14 px-8 laptop:px-8 border border-black/10 rounded-lg font-semibold flex items-center justify-center transition-all duration-300 ease-in-out hover:border-black/100 mobile:w-full"
                >
                  {t("Home.docs")}
                </a>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex-1 flex items-start justify-center pt-16 mobile:hidden tablet:hidden"
            >
              <div className="w-[560px] h-[560px] relative rounded-xl bg-black/5 overflow-hidden mobile:hidden tablet:hidden">
                <MatrixCanvas />
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
