import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const resources = {
  en: {
    "Navbar.logo": "pyrrha",
  },
};

Object.keys(resources).forEach((lang) => {
  i18n.addResourceBundle(
    lang,
    "translation",
    resources[lang as keyof typeof resources],
    true,
    true,
  );
});

const Navbar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <nav className="h-20 flex items-center justify-between border-b border-black/[0.08]">
      <div className="font-bold text-[1.25rem] tracking-[-0.02em]">
        {t("Navbar.logo")}
      </div>
    </nav>
  );
};

export default Navbar;
